import {
  useSession, signIn
} from 'next-auth/client';
import { Typography, Button } from 'antd';
import PropTypes from 'prop-types';
import Dashboard from './dashboard';
import { getBodyWidth } from '../components/functions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

export const equalizeStateWithSession = async (session) => {
  return {
    type: 'EQUALIZE_STATE_WITH_SESSION',
    payload: new Promise(resolve => resolve({
      email: session.user.email
    }))
  };
};

export const Index = (props) => {
  const [session] = useSession();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (session) {
      await dispatch(equalizeStateWithSession(session));
    }
  }, []);

  if (session) {
    return <Dashboard width={props.width} />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'black',
        height: '100vh'
      }}
    >
      <div
        style={{
          margin: '250px 180px 100px 180px',
          width: getBodyWidth(props.width)
        }}
      >
        <Title
          style={{
            color: 'white',
            fontSize: '32px'
          }}
        >
          Get it <span style={{ color: '#C753FE' }}>Berified</span>. With <span style={{ color: '#C753FE' }}>Portal</span>.
        </Title>

        <Button
          type='default'
          style={{
            borderColor: 'white',
            fontSize: 16,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black',
            color: 'white',
            paddingBottom: '32px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black'
            }
          }}
          onClick={signIn}
        >
          Sign in to your dashboard
        </Button>
      </div>
    </div>
  );
};

Index.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Index;
