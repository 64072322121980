import React, { Component } from 'react';
import {
  Input,
  Typography,
  Button,
  Table,
  Modal,
  notification,
  Spin
} from 'antd';
import { getBodyWidth } from '../components/functions';
import { PlusCircleFilled, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { withSession } from '../components/withSession';

const { Search, TextArea } = Input;
const { Text } = Typography;

// add GA
// delete product

const ProductRowItem = (text, record) => {
  return <a href={`/product/${record.id}`}>{text}</a>;
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: ProductRowItem
  },
  {
    title: 'Items',
    dataIndex: 'items',
    key: 'items'
  }
];

const antIcon = <LoadingOutlined style={{ color: 'white' }} spin />;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: false,
      name: '',
      description: '',
      products: [],
      displayProducts: []
    };
  }

  getProducts = async () => {
    const {
      data: { products }
    } = await axios.post(`${process.env.NEXT_PUBLIC_SERVER}/api/chain/products`, { email: this.props.session.user.email });
    let i = 0;
    for (const product of products) {
      product.key = ++i;
    }
    this.setState({
      products,
      displayProducts: products
    });
  };

  componentDidMount = async () => {
    await this.getProducts();
  };

  add = async () => {
    const { name, description } = this.state;
    this.setState({ loading: true });
    if (!name || !description) {
      this.setState({ loading: false });
      return notification['error']({
        message: 'Error',
        description: 'Product name or description is missing'
      });
    }
    await axios.post(`${process.env.NEXT_PUBLIC_SERVER}/api/chain/create`, {
      name,
      description,
      email: this.props.session.user.email
    });
    await this.getProducts();
    this.setState({
      loading: false,
      visible: false,
      name: '',
      description: ''
    });
    notification['success']({
      message: 'Success',
      description: 'New product registered!'
    });
  };

  render() {
    const { name, description } = this.state;
    const { width } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
        <div style={{ width: getBodyWidth(width) }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <div>
              <Button
                type='primary'
                style={{ backgroundColor: '#1700F1', borderColor: '#1700F1' }}
                onClick={() => {
                  window.open(
                    `${process.env.NEXT_PUBLIC_SERVER}/api/chain/csv?all=true`,
                    '_blank'
                  );
                }}
              >
                Export as CSV
              </Button>
            </div>
            <div style={{ marginRight: 10 }}>
              <Button
                ghost
                style={{ color: '#1700F1', borderColor: '#1700F1' }}
                onClick={() =>
                  window.open(
                    `${process.env.NEXT_PUBLIC_SERVER}/api/chain/json?all=true`,
                    '_blank'
                  )
                }
              >
                Export as JSON
              </Button>
            </div>
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}
          >
            <Search
              placeholder='Search for a product...'
              onChange={(e) => {
                const text = e.target.value;
                const regex = new RegExp(text, 'i');
                const filteredData = this.state.products.filter(product =>
                  regex.test(product.name) || regex.test(product.description)
                );
                this.setState({
                  displayProducts: filteredData
                });
              }}
              onSearch={(text) => {
                const regex = new RegExp(text, 'i');
                const filteredData = this.state.products.filter(product =>
                  regex.test(product.name) || regex.test(product.description)
                );
                this.setState({
                  displayProducts: filteredData
                });
              }}
            />
          </div>

          <div
            style={{
              marginTop: 25,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Text style={{ fontSize: 20, color: '#1700F1' }} strong>
                My Products
              </Text>
            </div>

            <div>
              <Button
                type='primary'
                icon={<PlusCircleFilled />}
                style={{ backgroundColor: '#1700F1', borderRadius: 5 }}
                onClick={() => this.setState({ visible: true })}
              >
                <Text strong style={{ color: 'white' }}>
                  Register a product
                </Text>
              </Button>
            </div>
          </div>

          <div style={{ marginTop: 25 }}>
            <Table
              columns={columns}
              dataSource={this.state.displayProducts}
              pagination={{ position: ['bottomCenter'] }}
              size='small'
            />
          </div>
        </div>

        <Modal
          centered
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <div style={{ marginTop: 20 }}>
            <div>
              <div>
                <Text strong style={{ color: '#1700F1' }}>
                  Name
                </Text>
              </div>
              <div>
                <Input
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <div>
                <Text strong style={{ color: '#1700F1' }}>
                  Description
                </Text>
              </div>
              <div>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Button
                type='primary'
                icon={this.state.loading ? null : <PlusCircleFilled />}
                style={{ backgroundColor: '#1700F1', borderRadius: 5 }}
                onClick={this.add}
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <Text strong style={{ color: 'white' }}>
                    Register
                  </Text>
                )}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

Dashboard.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  session: PropTypes.object
};

export default withRouter(connect(state => state)(withSession(Dashboard)));
